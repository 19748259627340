<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div v-if="!isAdminRoute()" class="col-lg-3 order-1 order-lg-0">
        <TriadMemberBlock />
        <ContractBlock />
        <DocumentsBlock />
      </div>
      <div :class="!isAdminRoute() ? 'col-lg-9 order-0 order-lg-1' : 'col-12'">
        <div class="widget">
          <div v-if="newsToDisplay && newsToDisplay.title">
            <div class="widget-heading flex-column">
              <div class="textStyle d-flex align-items-center mb-2">
                <button
                  class="btn-icon me-2 d-flex align-items-center"
                  v-tooltip="{ title: $t('back') }"
                  @click="$router.back()"
                >
                  <FontAwesomeIcon icon="fa-light fa-arrow-left" />
                </button>
                <h2 class="textStyle widget-title">
                  {{ newsToDisplay.title }}
                </h2>
              </div>
              <div class="d-flex">
                <span
                  :class="
                    'fw-bold badge me-2 ' +
                    (newsItem.tagBadge ? newsItem.tagBadge.color : 'blueBadge')
                  "
                  >{{
                    newsItem.tagBadge
                      ? newsItem.tagBadge.name
                      : $t("admin.news.list.title")
                  }}</span
                >
                -
                {{ $t("admin.news.list.uploaded_date") }}
                {{ formatedDate(newsToDisplay.dateToDisplay) }}
              </div>
            </div>
            <div class="widget-content news">
              <div class="news">
                <div class="news__card">
                  <div class="news__content">
                    <div class="news__text_container">
                      <div
                        v-if="newsToDisplay.subTitle"
                        class="news__subtitle"
                        v-html="newsToDisplay.subTitle"
                      ></div>
                      <div class="news__text" v-html="newsToDisplay.text"></div>
                    </div>
                    <div class="news__image d-sm-block d-none">
                      <img :alt="newsToDisplay.title" :src="picture" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { dateFormatter } from "@/services/utilities/date-formatter";
import { pictureManager } from "@/services/utilities/picture-manager";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { routeManager } from "@/services/utilities/route-manager";
import ContractBlock from "@/components/blocks/ContractBlock.vue";
import TriadMemberBlock from "@/components/blocks/TriadMemberBlock.vue";
import DocumentsBlock from "@/components/blocks/DocumentsBlock.vue";
import { useNewsStore } from "@/store/news/news";
import { useTriadStore } from "@/store/triad/triad";

export default {
  name: "NewsView",
  components: {
    DocumentsBlock,
    TriadMemberBlock,
    ContractBlock,
    FontAwesomeIcon,
  },
  data() {
    return {
      loading: true,
      picture: "",
      newsToDisplay: [],
      tag: null,
    };
  },
  computed: {
    ...mapState(useNewsStore, {
      news: (store) => store.news,
      newsItem: (store) => store.newsItem,
    }),
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
    }),
  },
  mounted() {
    if (this.isAdminRoute()) {
      this.setAdminNewsItem();
    } else {
      this.setNewsItem();
    }
  },
  watch: {
    newsItem() {
      if (this.isAdminRoute()) {
        this.setAdminNewsItem();
      }
    },
    triad() {
      if (!this.isAdminRoute()) {
        this.setNewsItem();
      }
    },
  },
  updated() {
    const iframe = document.querySelector("iframe");
    if (iframe) {
      iframe.setAttribute(
        "style",
        "width:100%;aspect-ratio:16/9;height:auto;max-width:600px;",
      );
    }
  },

  methods: {
    setBreadcrumbTitle() {
      if (this.newsToDisplay && this.newsToDisplay.title) {
        window.localStorage.setItem(
          "studea-pageTitle",
          JSON.stringify({ name: this.newsToDisplay.title }),
        );
      }
    },

    formatedDate(date) {
      return dateFormatter.format(date, "short");
    },

    isAdminRoute() {
      return routeManager.isNewsAdminRoute(this.$route.name);
    },

    setAdminNewsItem() {
      if (Object.keys(this.news).length === 0) {
        this.fetchNews().then(() => {
          this.toggleNews(this.$route.params.id);
        });
      } else {
        this.toggleNews(this.$route.params.id);
      }
      this.newsToDisplay = this.newsItem;
      if (this.newsToDisplay) {
        this.setBreadcrumbTitle();
        this.picture = pictureManager.setPicture(
          this.newsToDisplay.picture,
          "view",
        );
      }
    },

    setNewsItem() {
      if (this.triad && this.triad.news) {
        this.newsToDisplay = this.triad.news.find(
          (element) => element.id === Number(this.$route.params.id),
        );
      }
      if (this.newsToDisplay) {
        this.setBreadcrumbTitle();
        this.picture = pictureManager.setPicture(
          this.newsToDisplay.picture,
          "view",
        );
      }
    },

    ...mapActions(useNewsStore, {
      fetchNews: "fetchNews",
      toggleNews: "toggleNews",
    }),
  },
};
</script>
<style scoped lang="scss">
.widget {
  .btn {
    width: fit-content;
  }

  .widget-heading {
    .widget-title {
      font-size: 19px;
    }

    .redBadge {
      background-color: $red !important;
    }

    .blueBadge {
      background-color: $blue !important;
    }

    .greenBadge {
      background-color: $green !important;
    }

    .yellowBadge {
      background-color: $yellow !important;
    }

    a:hover {
      color: #21222a;
    }
  }
}

.news {
  &__card {
    border: none;
  }

  &__content {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__image {
    margin-left: 20px;

    img {
      height: 450px;
      width: 100%;
      object-fit: cover;
      border-radius: 0.375rem;
      @include up-to-lg {
        height: 350px;
      }
    }
  }

  &__text_container {
    width: 80%;
    @include mobile {
      width: 100%;
    }
  }

  &__text {
    margin-bottom: 0 !important;
    width: 100%;
    max-width: 100%;
    overflow: unset;
    text-overflow: initial;
    -webkit-line-clamp: initial;
  }

  &__subtitle {
    width: 100%;
    margin-bottom: 15px;
    font-size: 1rem;
    font-weight: 700;
  }
}

.btn {
  color: white !important;
}

.badge {
  width: fit-content;
}

.btn-icon {
  font-size: 19px;
}
</style>
