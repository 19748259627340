<template>
  <div class="w-100">
    <label
      v-if="itemModel.vars.label"
      :class="
        'form-label' +
        (itemModel.vars.label_attr && itemModel.vars.label_attr.class
          ? ' ' + itemModel.vars.label_attr.class
          : '') +
        (itemModel.vars.required ? ' required' : '')
      "
    >
      {{ index ? index + ". " : "" }}{{ itemModel.vars.label
      }}{{ itemModel.vars.required ? "*" : "" }}
      <span
        v-if="itemModel.vars.help && !isMobile()"
        v-tooltip="{ title: itemModel.vars.help }"
      >
        <FontAwesomeIcon icon="fa-light fa-circle-question" />
      </span>
    </label>
    <div v-if="isMobile() && itemModel.vars.help" class="form-info">
      <FontAwesomeIcon icon="fa-light fa-circle-question" />
      {{ itemModel.vars.help }}
    </div>
    <template v-if="!itemModel.vars.hide">
      <multiselect
        v-if="grouped"
        :id="itemModel.vars.id"
        v-model="itemModel.vars.value"
        :class="
          (itemModel.vars.error ? 'is-invalid' : '') +
          (itemModel.vars.attr && itemModel.vars.attr['data-taggable'])
            ? ' taggable'
            : ''
        "
        :deselectGroupLabel="$t('multiselect.press_enter_remove_group')"
        :deselectLabel="$t('multiselect.press_enter_remove')"
        :disabled="itemModel.vars.disabled === true"
        :group-select="isMultiple()"
        :hideSelected="isMultiple()"
        :internalSearch="!isAjaxSearch()"
        :limit="5"
        :loading="isLoading"
        :multiple="isMultiple()"
        :name="itemModel.vars.name"
        :options="choices"
        :placeholder="
          itemModel.vars.attr && itemModel.vars.attr.placeholder
            ? itemModel.vars.attr.placeholder
            : $t('multiselect.select_option')
        "
        :taggable="itemModel.vars.attr && itemModel.vars.attr['data-taggable']"
        :preserveSearch="true"
        :searchable="true"
        :selectGroupLabel="$t('multiselect.press_enter_group')"
        :selectLabel="$t('multiselect.press_enter')"
        :selectedLabel="$t('multiselect.selected')"
        :tagPlaceholder="$t('multiselect.press_enter_create_tag')"
        :allow-empty="!itemModel.vars.required || isMultiple()"
        group-label="group"
        group-values="options"
        label="label"
        openDirection="bottom"
        class="position-relative"
        track-by="value"
        @close="close"
        @open="open"
        @remove="valid"
        @searchChange="searchAjax"
        @select="valid"
        @tag="addTag"
      >
        <template v-slot:noOptions>
          <p v-if="isAjaxSearch">{{ $t("multiselect.no_options") }}</p>
        </template>
        <template v-slot:noResult>
          <p>{{ $t("multiselect.no_result") }}</p>
        </template>
        <template v-slot:clear>
          <button
            v-if="itemModel.vars.value && !itemModel.vars.required"
            type="button"
            class="btn-icon position"
            @click="remove"
            v-tooltip="{ title: $t('remove') }"
          >
            <FontAwesomeIcon icon="fa-light fa-xmark" />
          </button>
        </template>
      </multiselect>
      <multiselect
        v-else
        :id="itemModel.vars.id"
        v-model="itemModel.vars.value"
        :class="
          (itemModel.vars.error ? 'is-invalid' : '') +
          (itemModel.vars.attr && itemModel.vars.attr['data-taggable'])
            ? ' taggable'
            : ''
        "
        :deselectGroupLabel="$t('multiselect.press_enter_remove_group')"
        :deselectLabel="$t('multiselect.press_enter_remove')"
        :disabled="itemModel.vars.disabled === true"
        :hideSelected="isMultiple()"
        :internalSearch="!isAjaxSearch()"
        :limit="5"
        :loading="isLoading"
        :multiple="isMultiple()"
        :name="itemModel.vars.name"
        :options="choices"
        :placeholder="
          itemModel.vars.attr && itemModel.vars.attr.placeholder
            ? itemModel.vars.attr.placeholder
            : $t('multiselect.select_option')
        "
        :taggable="itemModel.vars.attr && itemModel.vars.attr['data-taggable']"
        :preserveSearch="true"
        :searchable="true"
        :selectGroupLabel="$t('multiselect.press_enter_group')"
        :selectLabel="$t('multiselect.press_enter')"
        :selectedLabel="$t('multiselect.selected')"
        :tagPlaceholder="$t('multiselect.press_enter_create_tag')"
        :allow-empty="!itemModel.vars.required || isMultiple()"
        label="label"
        openDirection="bottom"
        track-by="value"
        class="position-relative"
        @close="close"
        @open="open"
        @remove="valid"
        @searchChange="searchAjax"
        @select="valid"
        @tag="addTag"
      >
        <template v-slot:noOptions>
          <p v-if="isAjaxSearch">{{ $t("multiselect.no_options") }}</p>
        </template>
        <template v-slot:noResult>
          <p>{{ $t("multiselect.no_result") }}</p>
        </template>
        <template v-slot:clear>
          <button
            v-if="itemModel.vars.value && !itemModel.vars.required"
            type="button"
            class="btn-icon position"
            @click="remove"
            v-tooltip="{ title: $t('remove') }"
          >
            <FontAwesomeIcon icon="fa-light fa-xmark" />
          </button>
        </template>
      </multiselect>
    </template>
    <div ref="backdrop" class="modal-backdrop fade"></div>
    <div
      v-if="
        itemModel.vars.error &&
        (!itemModel.vars.attr ||
          (itemModel.vars.attr && !itemModel.vars.attr['data-hide-error']))
      "
      class="invalid-feedback d-block"
    >
      {{ itemModel.vars.error }}
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import "@suadelabs/vue3-multiselect/dist/vue3-multiselect.css";
import { formValidator } from "@/services/form/form-validator";
import { toRaw } from "vue";
import { apiConnection } from "@/services/api-connection";
import { mapState } from "pinia";
import { formManager } from "@/services/form/form-manager";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useThemeStore } from "@/store/theme/theme";

export default {
  name: "SelectTypeComponent",
  data() {
    return {
      itemModel: this.model,
      choices: [],
      entity: false,
      grouped: false,
      isLoading: false,
    };
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapState(useThemeStore, {
      locale: (store) => store.locale,
    }),
  },
  watch: {
    "itemModel.vars.choices"() {
      this.processChoices();
    },
    "model.vars.value"() {
      if (this.itemModel.vars.toggler_target) {
        this.$emit("toggle", this.itemModel);
      }
    },
    "model.vars.error"() {
      this.setInputValid();
    },
  },
  mounted() {
    this.processChoices(false);
    this.findSelected();
  },
  methods: {
    valid(value = null, id = null, remove = false) {
      this.$emit(
        "update",
        this.itemModel.vars.value &&
          Object.keys(this.itemModel.vars.value).length
          ? this.itemModel.vars.value.value
          : "",
      );
      if (this.itemModel.vars.toggler_target) {
        this.$emit("toggle", this.itemModel);
      }

      formValidator.validModel(this.itemModel);
      this.setInputValid();
      if (this.isAjaxLoad()) {
        this.searchAjax();
      }
      if (!remove) {
        this.findSelected();
      } else {
        console.log(value);
        console.log(id);
      }
      this.$emit("draft", this.itemModel);
    },

    setInputValid() {
      const input = document.getElementById(this.itemModel.vars.id);
      if (input) {
        const tags = input.parentElement;
        if (
          tags &&
          this.itemModel.vars.required &&
          (!this.itemModel.vars.value || this.itemModel.vars.value === "")
        ) {
          tags.classList.add("is-invalid");
          tags.classList.remove("is-valid");
        } else if (this.itemModel.vars.required) {
          tags.classList.remove("is-invalid");
          tags.classList.add("is-valid");
        }
      }
    },

    findSelected() {
      let selected = this.isMultiple() ? [] : null;
      let value = "";

      if (formManager.isMultiple(this.model)) {
        value = this.itemModel.vars.value;
      } else if (
        formManager.isEntityType(this.model) &&
        !formManager.isMultiple(this.model)
      ) {
        value =
          this.itemModel.vars.value &&
          typeof this.itemModel.vars.value === "object"
            ? this.itemModel.vars.value.value
            : this.itemModel.vars.value;
        if (!value && this.itemModel.vars.data && this.itemModel.vars.data.id) {
          value = this.itemModel.vars.data.id;
        }
      } else if (
        !formManager.isEntityType(this.model) &&
        !formManager.isMultiple(this.model)
      ) {
        value = this.itemModel.vars.value;
      }

      if (
        Object.keys(this.choices).length &&
        (value || typeof value === "number")
      ) {
        let grouped = false;
        Object.values(this.itemModel.vars.choices).forEach((choice) => {
          if (choice.choices) {
            if (this.isMultiple() && typeof value === "object") {
              selected = selected.concat(
                Object.values(choice.choices).filter((item) =>
                  value.includes(item),
                ),
              );
            } else {
              if (!selected) {
                selected = Object.values(choice.choices).find(
                  (item) => value && item.value === value.toString(),
                );
              }
            }
            grouped = true;
          }
        });
        if (!grouped) {
          if (this.isMultiple() && typeof value === "object") {
            selected = Object.values(this.choices).filter((item) =>
              value.includes(item),
            );
          } else {
            selected = Object.values(this.choices).find(
              (item) =>
                (value || typeof value === "number") &&
                item.value === value.toString(),
            );
          }
        }
      }
      if (selected) {
        this.itemModel.vars.value = selected;
      }
    },

    processChoices(valid = true) {
      let choices = this.itemModel.vars.choices;
      this.entity =
        this.itemModel.vars && formManager.isEntityType(this.itemModel);
      if (
        this.entity &&
        this.itemModel.vars.choices &&
        Object.keys(this.itemModel.vars.choices).length
      ) {
        choices = Object.values(this.itemModel.vars.choices);
      }
      if (choices && choices.length) {
        choices.forEach((choice) => {
          if (choice.choices && Object.keys(choice.choices).length) {
            this.choices.push({
              group: choice.label,
              options: Object.values(toRaw(choice.choices)),
            });
            this.grouped = true;
          }
        });

        if (!this.grouped) {
          this.choices = choices;
        }
      } else {
        this.choices = [];
      }
      if (
        this.itemModel.vars.attr &&
        this.itemModel.vars.attr["data-taggable"]
      ) {
        this.itemModel.vars.value = this.choices;
      }
      if (valid) {
        this.valid();
      }
    },

    isMultiple() {
      return formManager.isMultiple(this.itemModel);
    },

    isMobile() {
      return window.innerWidth < 992;
    },

    isAjaxSearch() {
      return formManager.isAjaxSearch(this.itemModel);
    },

    isAjaxLoad() {
      return formManager.isAjaxLoad(this.itemModel);
    },

    searchAjax(query = "") {
      if (this.isAjaxLoad() && !query && this.itemModel.vars.value) {
        if (
          (!this.itemModel.vars.value.data ||
            this.itemModel.vars.value.data.id) &&
          this.itemModel.vars.attr["data-ajax-linked-to"]
        ) {
          this.$emit("ajaxTargetTrig", {
            target: this.itemModel.vars.attr["data-ajax-linked-to"],
            trigger: this.itemModel,
          });
        } else if (
          this.itemModel.vars.value.data &&
          this.itemModel.vars.value.data.id
        ) {
          formManager.fieldLoad(this.itemModel, this);

          if (Object.keys(this.itemModel.vars.attr["data-ajax-load"]).length) {
            this.$emit("resetInputs", {
              item: this.itemModel.vars.attr["data-ajax-load"],
            });
          }
        }
      } else if (this.isAjaxSearch() && query && query.length > 1) {
        this.isLoading = true;
        const url = this.itemModel.vars.attr["data-ajax-url"] + "/" + query;
        apiConnection.get(url).then((data) => {
          this.itemModel.vars.choices = { ...data };
          this.processChoices();
          this.isLoading = false;
        });
      }
    },

    open() {
      this.$emit("focus");
      if (this.isMobile()) {
        const backdrop = this.$refs.backdrop;
        const body = document.getElementById("body");
        body.classList.add("modal-multiselect");
        backdrop.classList.add("show");
      }
    },

    close() {
      if (this.isMobile()) {
        const backdrop = this.$refs.backdrop;
        const body = document.getElementById("body");
        body.classList.remove("modal-multiselect");
        backdrop.classList.remove("show");
      }
    },

    addTag(newTag) {
      this.itemModel.vars.value.push({
        label: newTag,
        value: newTag,
        data: newTag,
      });
      this.choices = this.itemModel.vars.value;
      this.valid();
    },

    remove() {
      this.itemModel.vars.value = this.isMultiple() ? [] : null;
      this.valid(null, null, true);
      this.$emit("resetInputs", {
        item: this.itemModel.vars.attr["data-ajax-load"],
      });
    },
  },
  components: {
    FontAwesomeIcon,
    Multiselect,
  },
};
</script>

<style lang="scss" scoped>
.non-empty {
  overflow: auto;
}

.modal-backdrop {
  visibility: hidden;
  z-index: 10;

  &.show {
    visibility: visible;
  }
}

.position {
  position: absolute;
  top: 9px;
  right: 30px;
  z-index: 5;
  color: red;

  @include dark-theme {
    color: $white;
  }
}
</style>
