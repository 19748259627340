<template>
  <div
    ref="contentModal"
    aria-hidden="true"
    aria-labelledby="contentModal"
    class="modal fade"
    role="dialog"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content" v-if="news">
        <div class="modal-header d-flex">
          <div class="d-flex flex-column align-items-start">
            <h5 v-if="news.title" class="modal-title textStyle">
              {{ news.title }}
            </h5>
            <div>
              <span class="'fw-bold badge blueBadge">{{
                $t("admin.news.list.title")
              }}</span>
              -
              <span class="text-black" v-if="news.dateToDisplay">
                {{ $t("admin.news.list.uploaded_date") }}
                {{ formatedDate(news.dateToDisplay) }}
              </span>
            </div>
          </div>

          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            data-dismiss="modal"
            type="button"
          ></button>
        </div>
        <div class="modal-body d-flex flex-column flex-md-row">
          <div class="col-12 col-md-8 pe-2 d-flex flex-column">
            <h6 v-if="news.subTitle">{{ news.subTitle }}</h6>
            <p v-if="news.text" v-html="news.text"></p>
          </div>
          <div class="col-12 col-md-4" v-if="picture && news.title">
            <img class="news__img" :src="picture" :alt="news.title" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import { dateFormatter } from "@/services/utilities/date-formatter";

export default {
  name: "NewsModalComponent",
  props: {
    news: {
      type: Object,
      required: false,
      default: () => null,
    },
    picture: {
      type: String,
      required: false,
      default: () => "",
    },
    opened: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  watch: {
    opened() {
      const container = this.$refs.contentModal;
      const modal = new Modal(container);
      if (this.opened) {
        modal.show();
      } else {
        modal.hide();
        this.$emit("hidden");
      }
    },
  },
  mounted() {
    this.$refs.contentModal.addEventListener("hidden.bs.modal", this.hidden);
  },
  methods: {
    hidden() {
      this.$emit("hidden");
      const container = this.$refs.contentModal;
      container.removeEventListener("click", this.stop);
    },

    stop(e) {
      e.stopPropagation();
    },

    formatedDate(date) {
      return dateFormatter.format(date, "short");
    },
  },
};
</script>

<style scoped lang="scss">
.news {
  &__img {
    max-height: 500px;
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 6px;
    @include up-to-md {
      max-height: 250px;
    }
  }
}

.blueBadge {
  background-color: $blue !important;
}
</style>
